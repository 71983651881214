






























import { Component, Mixins } from 'vue-property-decorator'
import ShipmentArticles from '@/modules/shipments/views/detail/components/ShipmentArticles.vue'
import InfoCard from '@/general/components/common/InfoCard.vue'
import CurrentStage from '@/modules/shipments/views/detail/components/CurrentStage.vue'
import ConsignmentService from '@/modules/shipments/services/ConsignmentService'
import { findConsignmentFromDatabase } from '@/general/services/DatabaseService'
import {
  GetConsignment_GetConsignment
} from "@/generated/GetConsignment";
import Popup from "@/general/components/common/Popup.vue";

@Component({
  components: {
    Popup,
    CurrentStage,
    ShipmentArticles,
    InfoCard
  }
})
export default class Scan extends Mixins(ConsignmentService) {
  init: boolean = false
  clickedReferencePopup = false

  created() {
    this.$store.commit('isOnConsignment', true)
    const params = this.$route.params

      this.$store.commit('loading/set', true)
      this.getConsignment(this.$route.params.consignment)
        .then(result => this.setConsignment(result))
        .catch(() => {
          this.$store.commit('snackbar/show', { color: 'error', text: 'Shipment not found, showing cached version' })
          findConsignmentFromDatabase(Number(this.$route.params.consignment)).then((result: GetConsignment_GetConsignment | undefined) => {
            if (result) {
              this.setConsignment(result)
            }
          }).catch(() => this.$store.commit('loading/set', false))
        }).finally( () => this.init = true)
  }

  setConsignment(consignment: GetConsignment_GetConsignment) {
    this.$store.commit('transport/set', consignment)
    this.$store.commit('loading/set', false)
  }

  getReferences(): string[] {
    const consignment: GetConsignment_GetConsignment = this.$store.state.transport.consignment
    const instructions = [...new Set(consignment.actors.flatMap(a => [a.pickup.instructions, a.delivery.instructions]).filter(s => !!s) as string[])].flatMap(s => s.split('\n'))
    if (consignment) {
      const array = Array.from(new Set([
          ...consignment.actors.flatMap(actor => [actor.pickup.identifier, actor.delivery.identifier]).filter(s => !!s) as string[],
          ...consignment.containers.flatMap(container => container.packets.flatMap(packet => packet.identifier)).filter(s => !!s) as string[]
      ].filter(identifier => identifier)))
      return [...array, ...instructions]
    } else return instructions
  }
}
